@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --white-rgb: 255, 255, 255;
  --foreground-rgb: 30, 30, 30;
  --background-start-rgb: 240, 4, 54;
  --background-end-rgb: 200, 2, 240;

}

@font-face {
  font-family: "dot";
  src: url("../public/fonts/DotGothic16-Regular.ttf");
}

body {
  font-family: "Ubuntu", sans-serif;
  color: "#3b3f5c";
  background: linear-gradient(to bottom right,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}


.sidebar-bg {

  background: linear-gradient(to bottom right,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

a {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-primary-700 {
  --tw-bg-opacity: 0.8;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.0);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.invert {
  filter: invert(100%);
}

.invert-50 {
  filter: invert(50%);
}

.content-wrapper {
  background-color: #f2f2f2;
}


.list-inline-item {
  display: inline-block;
}

.badge-collapsed-img img {
  width: 40px !important;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
  margin-left: -21px;
}

.bg-form-dialog {
  background-color: #f9fafb;
}

.rs-picker-popup {
  z-index: 999999;
}

.rs-input-group {
  border-radius: 0.75rem;
}

.rs-input-group:focus-within {
  border-color: rgba(168, 85, 247, 1) !important;
  outline: 3px solid rgba(168, 85, 247, 0.3);

}

.rs-input {
  border-radius: 0.75rem;
}

.rs-input:focus {
  border-color: rgba(168, 85, 247, 1) !important;
}

.editable {
  border: 0.3 solid red;
}

.editable:disabled {
  background-color: white !important;
}

.editable:focus-visible {
  outline: none;
}

.input-flat .rs-input-group {
  border-width: 0;

}

.input-flat .rs-input-group:focus-within {
  outline: none;
}

.input-flat .rs-input-group input {
  padding: 0;
  font-size: 14pt;
  color: black;
  border-radius: 0;
}

.input-flat .rs-input-group input:disabled {
  background-color: white !important;
  color: black;
}

.input-flat .rs-input-group input::selection {
  background: white;
}

.input-flat .rs-btn-close {
  display: none;
}

a:hover {
  text-decoration: none;
}

.tab-wrapper {
  @apply whitespace-nowrap overflow-x-auto rounded-full block py-1 transition-all bg-gray-200 cursor-pointer mt-4;
}

.tab-setting {
  @apply mx-2 inline-block rounded-full min-w-48 text-center py-3 px-6 transition-all cursor-pointer border font-medium text-gray-600
}

.tab-setting.active {
  @apply mx-2 rounded-full min-w-48 text-center py-3 px-6 transition-all bg-white cursor-pointer border-0 text-gray-900
}

.tab-setting:hover {
  @apply mx-2 rounded-full min-w-48 text-center py-3 px-6 transition-all shadow-lg bg-white cursor-pointer border-0 text-gray-900
}


.pos-product-cat {
  @apply px-7 py-3 bg-white rounded-full ml-4 cursor-pointer hover:shadow-md transition-all hover:px-8 
}
.pos-product-cat.active {
  @apply bg-orange-100 font-medium shadow-sm border border-orange-200
}


.search-active {
  @apply bg-green-100
}


.list-new ul, ul.disc {
  list-style-type: disc;
}